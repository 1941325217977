html {
  height: -webkit-fill-available;
}

h1,
h2,
h3,
h4 {
  font-family: 'Basier Circle', sans-serif;
  font-weight: 400;
  margin: 0.2em 0em;
}

p {
  font-family: 'Basier Square', sans-serif;
  color: rgba(var(--system-colour), 1);
  font-size: 13px;
  line-height: 1.5em;
  margin: 0px;
  font-weight: 400;
}

a:any-link {
  text-decoration: none !important;
}

h1 {
  color: rgba(var(--system-colour), 1);
  font-size: 20px;
}

h2 {
  color: rgba(var(--system-colour), 1);
  font-size: 16px;
}

h3 {
  color: rgba(var(--primary-colour), 1);
  font-size: 14px;
}

h4 {
  color: rgba(var(--secondary-colour), 1);
  font-size: 13px;
}

body {
  margin: 0px;
  height: 100vh;
  height: -webkit-fill-available;
  overflow-y: scroll;
}


.locked {
  overflow: hidden !important;
}

div#wrapper {
  margin: 0px;
  height: 100%;
  overflow-y: scroll;
}

.disable-scrollbars::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  /* Chrome/Safari/Webkit */
}

.disable-scrollbars {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

/* Step 1: App Landing Page */

div#root,
.appLandingContainer {
  background: rgba(var(--background-colour), 1);
  height: 100%;
}

.brokerContainer {
  position: absolute;
  height: 40px;
  box-sizing: border-box;
  padding: 10px 20px;
  margin: auto;
  display: flex;
  flex-direction: row;
  background: rgba(var(--background-colour), 0.8);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  z-index: 99;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 20px;
  border: 2px transparent solid;
  transition: all 0.2s linear;
}

.brokerLogo {
  width: 20px;
  height: 20px;
  flex-grow: 0;
  border-radius: 10px;
  overflow: hidden;
}

.brokerLogo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.brokerText p {
  /* margin: 0px 10px; */
  flex-grow: 1;
  white-space: nowrap;
  color: rgba(var(--primary-colour), 1);
  text-decoration: none;
  transition: all 0.2s linear;
}

.brokerText .brokerName {
  color: rgba(var(--system-colour), 1);
}

.brokerLaunchIcon {
  flex-grow: 0;
  width: 13px;
  height: 13px;
  margin-left: 10px;
  transition: all 0.2s linear;
}

.brokerLaunchIcon svg {
  fill: rgba(var(--primary-colour), 1);
  transition: all 0.2s linear;
}

.brokerContainer:hover {
  border: 2px rgba(var(--secondary-colour), 1) solid;
}

.brokerContainer:hover .brokerLaunchIcon svg {
  fill: rgba(var(--system-colour), 1);
}

.eventArtwork {
  position: relative;
  display: flex;
  flex-direction: row;
}

.artworkHeroContainer {
  box-sizing: border-box;
  padding: 20px;
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.artworkHero {
  width: 100%;
  aspect-ratio: 2/3;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 20px;
}

.artworkHero img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.artworkBackground {
  width: 100%;
  height: calc(100% + 5px);
  /* added height to fix clipping issue */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.artworkBackground .artworkBackgroundGradient {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(
    rgba(var(--background-colour), 0.1) 50%,
    rgba(var(--background-colour), 1)
  );
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.artworkBackground img {
  margin-left: -100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .artworkBackground img {
    opacity: 75%;
  }
}

section > *:first-child {
  margin-top: 0px;
}

section > *:last-child {
  margin-bottom: 0px;
}

.eventTitle {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
  justify-content: center;
  padding: 0px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: 0.2s linear;
}

.eventTitle > * {
  margin: 10px 0px;
}

.eventTitle.inactive {
  opacity: 0;
}

.eventOverview {
  box-sizing: border-box;
}

.eventOrganiserContainer {
  display: flex;
  min-height: 40px;
}

.eventOrganiserContainer .organiserLogo {
  height: 40px;
  width: 40px;
  margin-right: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 10px;
  overflow: hidden;
  border: solid 2px rgba(var(--secondary-colour), 1);
}

.eventOrganiserContainer .organiserLogo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.eventOrganiserContainer .organiserName {
  width: 100%;
  margin-left: 20px;
}

.eventDetails {
  width: 100%;
  box-sizing: border-box;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  opacity: 1;
  transition: 0.2s linear;
}

.eventDetails.inactive {
  opacity: 0;
}

.innerWidth {
  margin: 10 20px;
}

.outerWidth {
  margin: 10px 0px;
}

.eventInformationCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
  background: rgba(var(--secondary-colour), 0.1);
}

.eventInformationIcon {
  width: 15px;
  height: 15px;
}

.eventInformationIcon svg {
  width: 15px;
  height: 15px;
  fill: rgba(var(--secondary-colour), 1);
}

.eventInformationText {
  width: 100%;
  margin-left: 20px;
}

.eventInformationText h3 {
  color: rgba(var(--system-colour), 1);
}

.eventDetails > *:nth-last-child(2) {
  padding-bottom: 40px;
  margin-bottom: 0px;
}

.eventDescriptionContainer p {
  margin-top: 15px;
  font-weight: 400;
  font-size: 13px;
}

.ctaContainer::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 40px;
  top: -40px;
  background-image: linear-gradient(
    rgba(var(--background-colour), 0),
    rgba(var(--background-colour), 1)
  );
}

.ctaContainer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  background-color: rgba(var(--background-colour), 1);
  align-items: center;
}

.ctaContainer .ctaText {
  flex-grow: 0;
}

.ctaContainer .ctaText h3 {
  color: rgba(var(--system-colour), 1);
}

.ctaContainer .ctaText p {
  color: rgba(var(--secondary-colour), 1);
  line-height: 0.5em;
}

.ctaContainer .ctaButton {
  margin-left: 20px;
  flex-grow: 1;
  height: 50px;
  border-radius: 10px;
  background: rgba(var(--system-colour), 1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s linear;
}

.ctaContainer .ctaButton:active {
  opacity: 0.8;
}

.ctaContainer .ctaButton h3 {
  font-family: 'Basier Circle', sans-serif;
  font-weight: 500;
  vertical-align: middle;
  color: rgba(var(--background-colour), 1);
  font-size: 14px;
  text-decoration: none;
}

@media screen and (min-width: 700px) {
  .artworkHeroContainer,
  .eventTitle,
  .eventDetails {
    max-width: 700px;
    margin: auto;
  }
  .appCheckoutContainer {
    max-width: 700px;
    margin: auto;
  }
}

/* Checkout Flow Container */

.appCheckout {
  visibility: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: rgba(var(--background-colour), 0.5);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  opacity: 0;
  z-index: 10;
  transition: all 0.2s linear;
  transition: display 0.2s linear;
}

.appCheckout.active {
  visibility: visible;
  opacity: 1;
}

.appCheckoutContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.2s linear;
  transition-delay: 0.5s;
  opacity: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
}

.appCheckout.active .appCheckoutContainer {
  transform: translateY(0%);
  opacity: 1;
}

.cardNavigationControls {
  height: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.iconBackOrClose {
  position: relative;
  align-self: flex-start;
  width: 20px;
  height: 20px;
  transition: all 0.2s linear;
  transition-delay: 0.2s;
  margin-left: 0px;
}

.iconBackOrClose:not(.close) {
  margin-left: 0px;
  width: 12px;
}

.appCheckout .cardNavigationControls svg.iconClose {
  position: absolute;
  width: 20px;
  top: 0;
  left: 0;
  fill: rgba(var(--primary-colour), 1);
  opacity: 0;
  transition-delay: 2s;
  transition: all 0.2s linear;
  cursor: pointer;
}

.appCheckout .cardNavigationControls svg.iconBack {
  position: absolute;
  width: 20px;
  top: 0;
  right: 0;
  fill: rgba(var(--primary-colour), 1);
  opacity: 0;
  transition-delay: 2s;
  transition: all 0.2s linear;
  cursor: pointer;
}

.appCheckout.active .cardNavigationControls svg.iconClose {
  opacity: 1;
}

.appCheckout.active .cardNavigationControls svg.iconBack {
  opacity: 0;
}

.cardNavigationControls .iconBackOrClose:not(.close) svg.iconClose {
  opacity: 0;
  transition-delay: 0.2s;
}

.cardNavigationControls .iconBackOrClose:not(.close) svg.iconBack {
  opacity: 1;
}

.appcheckout .eventOverview {
  margin-top: auto;
  margin-bottom: 20px;
}

.appCheckoutCard {
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 20px;
  overflow: hidden;
  background-color: rgba(var(--background-colour), 1);
}

.appCheckoutStep {
  width: 100%;
  height: 100%;
}

.appCheckoutStepHeading {
  padding: 10px 20px;
}

p.appCheckoutStepDescription {
  color: rgba(var(--primary-colour), 1);
}

/* Step 2: Ticket Selection */

.checkoutTicketType {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  border: 2px transparent solid;
  background-color: rgba(var(--secondary-colour), 0.1);
  border-radius: 10px;
  align-items: center;
  transition: all 0.2s linear;
  cursor: pointer;
  overflow: hidden;
}

.checkoutTicketType:hover {
  background-color: rgba(var(--secondary-colour), 0.2);
  border: 2px rgba(var(--secondary-colour), 1) solid;
}

.checkoutTicketType.selected {
  background-color: rgba(var(--secondary-colour), 0.2);
  border: 2px rgba(var(--system-colour), 1) solid;
}

.checkoutTicketType.hidden {
  display: none;
}

.checkoutTicketType .ticketInformation {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  align-items: center;
}

.checkoutTicketType .ticketDescription {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.checkoutTicketType .ticketNote {
  width: 100%;
  margin-top: 10px;
}

.checkoutTicketType .ticketName {
  flex-basis: 100%;
}

.checkoutTicketType .ticketPrice {
  flex-shrink: 0;
  color: rgba(var(--system-colour), 1);
}

.checkoutTicketType .ticketPrice > .ticketCurrency {
  font-size: 13px;
}

.checkoutTicketType .ticketPrice > .ticketFees {
  color: rgba(var(--secondary-colour), 1);
  margin-left: 5px;
}

.checkoutTicketType .ticketPrice > .minor {
  font-size: 13px;
  font-weight: 100;
}

.checkoutTicketType .ticketIcon {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 9px;
  height: 15px;
  overflow: hidden;
  transition: all 0.2s linear;
}

.checkoutTicketType.selected .ticketIcon {
  width: 15px;
}

.checkoutTicketType .ticketIcon svg {
  position: absolute;
  top: 0;
  left: 0;
  fill: rgba(var(--secondary-colour), 1);
  width: 15px;
  left: 0px;
  transition: all 0.2s linear;
}

.checkoutTicketType .ticketIcon svg#iconProceed,
.checkoutTicketType.selected .ticketIcon svg#iconCancel {
  opacity: 1;
}

.checkoutTicketType .ticketIcon svg#iconCancel {
  opacity: 0;
}

/* Step 3: Select Ticket Quantity */

.ticketQuantitySelectorContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  transition: all 0.2s linear;
}

.ticketQuantitySelectorContainer.hidden {
  display: none;
}

.ticketQuantitySelector {
  display: flex;
  flex-direction: row;
  min-width: 50%;
  margin: 20px 0px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.ticketQuantityLabel {
  margin: 20px 0px;
}

.ticketQuantityButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ticketQuantitySelector .ticketQuantityButton {
  font-family: 'Basier Square', sans-serif;
  color: rgba(var(--secondary-colour), 1);
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--secondary-colour), 0.1);
  border: 2px transparent solid;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.2s linear;
  cursor: pointer;
  text-align: center;
}

.ticketQuantitySelector .ticketQuantityButton:hover {
  color: rgba(var(--system-colour), 1);
  background-color: rgba(var(--secondary-colour), 0.2);
  border: 2px rgba(var(--secondary-colour), 1) solid;
}

.ticketQuantitySelector .ticketQuantityButton.selected {
  color: rgba(var(--system-colour), 1);
  background-color: rgba(var(--secondary-colour), 0.2);
  border: 2px rgba(var(--system-colour), 1) solid;
}

.ticketOrderSummaryContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.ticketOrderSummaryRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ticketOrderSummaryRow .ticketOrderSummaryRowTitle,
.ticketOrderSummaryRow .ticketOrderSummaryRowFigure {
  color: rgba(var(--primary-colour), 1);
}

.ticketOrderSummaryRow.total .ticketOrderSummaryRowTitle,
.ticketOrderSummaryRow.total .ticketOrderSummaryRowFigure {
  font-size: 14px;
  color: rgba(var(--system-colour), 1);
}

.ticketQuantityDisclaimerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.ticketQuantityDisclaimerButton {
  margin-right: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

p.ticketQuantityDisclaimer {
  width: 100%;
  font-size: 12px;
  line-height: 14px;
}

.bulletCheck {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.bulletCheck .bullet {
  fill: rgba(var(--secondary-colour), 1);
}

.bulletCheck .bulletChecked {
  fill: rgba(var(--system-colour), 1);
  opacity: 0;
  transform-origin: center;
  transform: scale(0);
  transition: all 0.2s linear;
}

.bulletCheck.checked .bulletChecked {
  opacity: 1;
  transform: scale(1);
}

.ticketQuantityDisclaimer {
  color: rgba(var(--secondary-colour), 1);
  font-size: 13px;
  text-align: left;
  flex-basis: 100%;
}

.ticketQuantityDisclaimer a {
  color: rgba(var(--system-colour), 1);
  text-decoration: none;
}

.buttonProceed {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  margin-top: 10px;
  background-color: rgba(var(--secondary-colour), 0.1);
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
  border-radius: 10px;
  filter: blur(0px);
  opacity: 1;
  transition: all 0.2s linear;
  cursor: pointer;
}

.buttonProceed p {
  font-size: 14px;
  text-align: center;
  color: rgba(var(--secondary-colour), 1);
}

.buttonProceed:not(.blocked):hover {
  color: rgba(var(--secondary-colour), 1);
}

.buttonProceed:not(.blocked):active {
  opacity: 0.8;
}

apple-pay-button {
  filter: blur(0px);
  margin-top: 20px;
  box-sizing: border-box;
  --apple-pay-button-width: 100%;
  --apple-pay-button-height: 30px;
  --apple-pay-button-border-radius: 10px;
  --apple-pay-button-padding: 10px 0px;
  transition: all 0.2s linear;
}

.blocked {
  filter: blur(2px);
  opacity: 0.5;
  pointer-events: none;
}

::-webkit-scrollbar {
  width: 2px;
  height: 10px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border: rgba(var(--secondary-colour), 0.1);
  border-radius: 50px;
  transition: all 0.2s linear;
}

::-webkit-scrollbar-thumb:hover {
  border: rgba(var(--secondary-colour), 1);
}

::-webkit-scrollbar-thumb:active {
  border: rgba(var(--system-colour), 1);
}

::-webkit-scrollbar-track {
  background: transparent;
  border: rgba(var(--secondary-colour), 0.1);
  border-radius: 53px;
}

::-webkit-scrollbar-track:hover {
  background: transparent;
}

::-webkit-scrollbar-track:active {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.ctaContainer .ctaButton {
  background: rgba(var(--system-colour), 1);
}

.ctaContainer .ctaButton:active {
  opacity: 0.8;
}

.ctaContainer .ctaButton h3 {
  color: rgba(var(--system-colour), 1);
  -webkit-filter: invert();
  filter: invert();
  font-weight: 400;
}

.appCheckout {
  justify-content: flex-end;
}

.cardNavigationControls {
  margin-bottom: auto;
}

.appCheckoutEventOverview {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 20px;
  margin-bottom: 20px;
}

.appCheckoutEventOverview .appCheckoutEventOverviewImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 20px;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  border: 2px solid rgba(var(--system-colour), 1);
}

.appCheckoutEventOverview .appCheckoutEventOverviewImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.appCheckoutEventOverview .appCheckoutEventOverviewText {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.appCheckoutEventOverview .appCheckoutEventOverviewText * {
  margin: 0px;
}

.appCheckoutStep {
  width: 100%;
  display: none;
  overflow-y: scroll;
}

.appCheckoutStep.active {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.buttonProceed {
  min-height: 50px;
  background-color: rgba(var(--system-colour), 1);
  transition: all 0.2s linear;
}

.buttonProceed p {
  color: rgba(var(--system-colour), 1);
  filter: invert();
  -webkit-filter: invert();
  transition: all 0.2s linear;
}

.buttonProceed.muted {
  background-color: rgba(var(--secondary-colour), 0.1);
}

.buttonProceed.muted p {
  color: rgba(var(--secondary-colour), 1);
  filter: none;
  -webkit-filter: none;
}

.buttonProceed.muted:hover {
  background-color: rgba(var(--secondary-colour), 0.2);
}

.buttonProceed.muted:hover p {
  color: rgba(var(--system-colour), 1);
}

/* -- START Form Styling --*/

form {
  padding: 20px 0px;
  margin-block-end: 0px;
  transition: all 0.2s linear;
  opacity: 1;
  height: auto;
}

form.hidden {
  display: none;
  overflow-y: hidden;
}

form input::placeholder {
  font-family: 'Basier Circle', sans-serif;
  color: rgba(var(--secondary-colour), 1);
}

form input,
form input::placeholder {
  font-size: 14px;
}

form .form-row {
  position: relative;
  width: 100%;
}

form .form-row:not(:first-child) {
  position: relative;
  width: 100%;
  margin-top: 25px;
}

form .form-row:last-child {
  margin-bottom: 0px;
}

form .form-field {
  /* width: 100%; */
  border-radius: 5px;
  border: solid 2px rgba(var(--secondary-colour), 0.25);
  transition: border 0.2s linear;
  box-sizing: border-box;
}

form .form-field input::placeholder {
  color: transparent;
}

form .form-field:hover {
  border: solid 2px rgba(var(--secondary-colour), 1);
}

form .form-field label {
  font-family: 'Basier Square', sans-serif;
  color: rgba(var(--secondary-colour), 1);
  font-size: 14px;
  letter-spacing: 0px;
  padding: 5px 10px;
  position: absolute;
  margin-left: 10px;
  transform: translateY(-50%);
  background: rgba(var(--background-colour), 1);
  transition: color 0.2s linear;
}

form .form-row-double {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 700px) {
  form .form-row-double {
    flex-direction: column;
    width: 100%;
  }
  form .form-row-double .form-field {
    width: 100% !important;
    margin-top: 20px;
  }
}

form .form-field input {
  font-family: 'Basier Square', sans-serif;
  color: rgba(var(--system-colour), 1);
  background: transparent;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  outline: none !important;
  box-sizing: border-box;
  width: 100%;
}

input[type='password'i] {
  letter-spacing: 0.25em;
}

input[type='password'i]:autofill {
  letter-spacing: 0em;
}

form .form-row-double .form-field {
  width: calc(50% - 10px);
}

form .form-field:focus-within {
  border: solid 2px rgba(var(--system-colour), 1);
}

form .form-field:focus-within input::placeholder {
  color: rgba(var(--secondary-colour), 0.75);
}

form .form-field:focus-within label {
  color: rgba(var(--system-colour), 1) !important;
}

fieldset {
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-block-start: 0px;
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  padding-block-end: 0px;
  min-inline-size: none;
  border: none;
}

/* END Form Styling */

form.formContactDetails {
  padding-top: 15px;
}

.blocked {
  cursor: not-allowed;
}

.checkoutAttendeeDetailsOption {
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0px;
  border: 2px transparent solid;
  background-color: rgba(var(--secondary-colour), 0.1);
  border-radius: 10px;
  transition: all 0.2s linear;
  cursor: pointer;
  overflow: hidden;
}

.checkoutAttendeeDetailsOption:last-child {
  margin-top: 0px;
}

.checkoutAttendeeDetailsOption h2 {
  color: rgba(var(--system-colour), 1);
  font-size: 14px;
}

.checkoutAttendeeDetailsOption:hover {
  background-color: rgba(var(--secondary-colour), 0.2);
  border: 2px rgba(var(--secondary-colour), 1) solid;
}

.checkoutAttendeeDetailsOption.selected {
  background-color: rgba(var(--secondary-colour), 0.2);
  border: 2px rgba(var(--system-colour), 1) solid;
}

.checkoutAttendeeDetailsOption.hidden {
  display: none;
}

.checkoutAttendeeDetailsOption .checkoutAttendeeDetailsOptionText {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  align-items: flex-start;
  margin-left: 20px;
}

#attendeeDetailsAutomatic {
  margin-bottom: 0px;
}

.attendeeProfileContainer {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
  margin-top: 20px;
  border-radius: 10px;
  background: rgba(var(--secondary-colour), 0.1);
  transition: all 0.2s linear;
}

.attendeeProfileContainer.hidden {
  display: none;
}

.attendeeProfilePhoto {
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.attendeeProfileContainer .attendeeProfilePhoto {
  width: 40px;
  height: 40px;
  margin-right: 20px;
  border-radius: 20px;
}

.attendeeProfilePhoto img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.attendeeProfileDetails {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.ticketQuantityDisplay {
  display: flex;
  flex-direction: row;
  min-width: 50%;
  box-sizing: border-box;
  padding: 0px 20px;
  margin: 20px 0px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.ticketQuantityDisplay .ticketQuantityIconsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.ticketQuantityDisplay .ticketQuantityIcon {
  font-family: 'Basier Square', sans-serif;
  font-size: 14px;
  color: rgba(var(--secondary-colour), 1);
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  margin: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--secondary-colour), 0.1);
  border: 2px transparent solid;
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.2s linear;
  text-align: center;
  position: relative;
}

.ticketQuantityDisplay .ticketQuantityIcon:first-child {
  margin-left: 0px;
}

.ticketQuantityDisplay .ticketQuantityIcon:last-child {
  margin-right: 0px;
}

.ticketQuantityDisplay .ticketQuantityIcon.completed {
  color: rgba(var(--system-colour), 1);
  background-color: rgba(var(--secondary-colour), 0.2);
}

.ticketQuantityDisplay .ticketQuantityIcon.active {
  color: rgba(var(--system-colour), 1);
  border: 2px rgba(var(--system-colour), 1) solid;
}

.formInstructionText {
  margin: 20px 0px;
  padding: 0px 20px;
}

.formInstructionText h4 {
  color: rgba(var(--primary-colour), 1);
}

.checkoutTicketType.selected {
  flex-shrink: 0;
}

@media screen and (max-width: 700px) {
  .ticketQuantitySelector {
    margin-top: 0px;
  }
}

.feedbackContainer {
  padding-bottom: 10px;
}

.feedbackRatingContainer {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 20px;
  flex-shrink: 0;
  max-width: 300px;
  justify-content: space-between;
  align-items: center;
  background: rgba(var(--secondary-colour), 0.1);
  border-radius: 20px;
  transition: all 0.2s linear;
}

.feedbackRatingContainer.hidden {
  display: none;
  opacity: 0;
}

.feedbackRatingContainer .feedbackRatingOption {
  display: flex;
  flex-direction: column;
  min-width: 40px;
  width: calc(25% - 15px);
  max-width: 60px;
  height: 70px;
  border-radius: 10px;
  justify-content: center;
  transition: all 0.2s linear;
  cursor: pointer;
  border: 2px rgba(var(--secondary-colour), 0) solid;
}

.feedbackRatingContainer .feedbackRatingOption > * {
  text-align: center;
  transition: all 0.2s linear;
}

.feedbackContainer input {
  display: none;
}

.feedbackContainer label {
  cursor: pointer;
}

.feedbackRatingContainer .feedbackRatingOption:hover {
  background: rgba(var(--secondary-colour), 0.2);
  border: 2px rgba(var(--secondary-colour), 1) solid;
}

.feedbackRatingContainer .feedbackRatingOption.selected {
  background: rgba(var(--secondary-colour), 0.2);
  border: 2px rgba(var(--system-colour), 1) solid;
}

.feedbackRatingContainer .feedbackRatingOption.selected > * {
  color: rgba(var(--system-colour), 1);
}

.feedbackRatingContainer .feedbackRatingOption.muted {
  filter: blur(1px);
  opacity: 0.8;
}

.feedbackRatingContainer .feedbackRatingOption.muted:hover {
  filter: blur(0px);
}

.appCheckoutStepHeading {
  padding: 0px 20px;
}

.feedbackResponseContainer {
  box-sizing: border-box;
  padding: 0px 20px;
  margin: 10px 0px;
  transition: all 0.2s linear;
}

.feedbackResponseContainer.hidden {
  display: none;
  opacity: 0;
}

.feedbackResponseContainer .feedbackResponseText span {
  color: rgba(var(--system-colour), 1);
  transition: all 0.2s linear;
}

.feedbackResponseContainer .feedbackResponseText span.hidden {
  display: none;
  opacity: 0;
}

.buttonProceed.hidden {
  display: none;
  opacity: 0;
}

.feedbackResponseContainer {
  display: flex;
  flex-direction: row;
  margin: 20px 0px;
  flex-wrap: wrap;
  align-items: center;
}

.feedbackResponseContainer .feedbackResponseText {
  width: 100%;
  flex-shrink: 0;
  flex-basis: 100%;
}

.feedbackResponseContainer label {
  display: flex;
  flex-direction: row;
  margin: 10px 0px;
  margin-right: 40px;
  align-items: center;
}

.feedbackResponseContainer .bulletCheck {
  margin-right: 20px;
}

/* @Liam Start of the Second Build here */

html,
body {
  width: 100vw;
  overflow-x: hidden;
}

.appCheckoutContainer.final {
  overflow-x: visible;
}

.appCheckoutContainer.final .cardNavigationControls {
  opacity: 0;
}

.appCheckoutCard.final {
  height: 100%;
  background-color: transparent;
  padding: 0px;
  /* overflow: visible; */
}

:root {
  --slider-size: min(calc(100% - 40px), 400px);
  --slider-fullbleed-gap: 10px;
}

.ticketsPurchasedSlider {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  margin: auto;
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
  padding-top: 100px;
}

.ticketsPurchasedSlider {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ticketsPurchasedSlider::-webkit-scrollbar {
  display: none;
}

.ticketPurchasedWrapper {
  scroll-snap-align: center;
  margin-right: var(--slider-fullbleed-gap);
  flex: 0 0 var(--slider-size);
  width: var(--slider-size);
  box-sizing: content-box;
  display: flex;
}

.ticketsPurchasedSlider .ticketPurchasedWrapper:first-child {
  padding-left: calc(50vw - (var(--slider-size) / 2));
}

.ticketsPurchasedSlider .ticketPurchasedWrapper:last-child {
  margin-right: 0;
  padding-right: calc(50vw - (var(--slider-size) / 2));
}

.ticketPurchased {
  flex: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
}

.ticketPurchased > * .content {
  background: rgba(var(--background-colour), 1);
  position: relative;
  overflow: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
}

.ticketPurchased > .upper .ticketStyling.bottom .middle {
  border-bottom: dotted 2px rgba(var(--secondary-colour), 0.2);
}

.ticketStyling {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.ticketStyling .corner {
  width: 10px;
  height: 10px;
  flex-grow: 0;
  flex-shrink: 0;
  fill: rgba(var(--background-colour), 1);
}

.ticketStyling .middle {
  width: 100%;
  flex-basis: 100%;
  height: 10px;
  background: rgba(var(--background-colour), 1);
  box-sizing: border-box;
}

.ticketPurchased .attendeeDetails {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ticketPurchased .attendeeAvatar {
  width: 50px;
  height: 50px;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 25px;
  overflow: hidden;
  border: solid 2px rgba(var(--primary-colour), 1);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ticketPurchased .attendeeAvatar img {
  width: 100%;
  height: auto;
}

.ticketPurchased .attendeeName {
  width: 100%;
  flex-basis: 100%;
  margin-left: 20px;
}

.ticketPurchased .ticketQRCodeContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 10px;
  /* padding-right: 20px; */
  background: rgba(var(--secondary-colour), 0.2);
}

.ticketPurchased .ticketQRCodeContainer > img {
  width: 60px;
  height: auto;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 5px;
}

.ticketPurchased .ticketQRCodeContainer .ticketQRCodeText {
  margin-left: 20px;
  width: 100%;
  flex-basis: 100%;
}

.ticketPurchased .ticketQRCodeContainer .ticketQRCodeText > h3 {
  color: rgba(var(--secondary-colour), 1);
}

.ticketPurchased .ticketQRCodeContainer .ticketQRCodeText > h1 {
  color: rgba(var(--system-colour), 1);
}

.ticketPurchased .ticketInformationContainer {
  width: 100%;
  box-sizing: border-box;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ticketPurchased
  .ticketInformationContainer
  .ticketInformation:not(:last-child) {
  margin-bottom: 10px;
}

.ticketPurchased .ticketInformationContainer .ticketInformation.fullWidth {
  width: 100%;
  flex-basis: 100%;
}

.ticketPurchased .ticketInformationContainer .ticketInformation.halfWidth {
  width: calc(50% - 5px);
}

.ticketPurchased .ticketInformationContainer .ticketInformation h4 {
  color: rgba(var(--primary-colour), 1);
}

.ticketPurchased .ticketInformationContainer .ticketInformation h3 {
  color: rgba(var(--system-colour), 1);
}

.containerSelectedTicketButtons {
  box-sizing: border-box;
  margin-top: 20px;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
}

.buttonAddToWallet {
  display: flex;
  width: fit-content;
  cursor: pointer;
  justify-content: center;
}

.buttonAddToWallet img {
  height: 50px;
  margin: auto;
}

.buttonSendAsEmail {
  background: rgba(var(--secondary-colour), 1);
  margin-top: 0px;
}

.buttonSendAsEmail svg#iconSend {
  width: 10px;
  height: 10px;
  fill: rgba(var(--background-colour), 1);
  margin-right: 2px;
}

.buttonSendAsEmail p {
  color: rgba(var(--background-colour), 1);
  filter: none;
  -webkit-filter: none;
}

.ticketPurchased .ticketBranding h4 {
  color: rgba(var(--secondary-colour), 0.5);
  text-align: center;
  margin-top: 30px;
  transition: all 0.2s linear;
}

.ticketPurchased .ticketBranding #pinSolid {
  display: inline;
  width: 16px;
  height: auto;
  fill: rgba(var(--secondary-colour), 0.8);
  margin-left: 5px;
  margin-right: 5px;
}

.ticketPurchased .ticketBranding h4 strong {
  color: rgba(var(--secondary-colour), 0.8);
  font-weight: normal;
}

.ticketPurchased .ticketBranding a {
  cursor: pointer;
}

.ticketPurchased .ticketBranding a:hover strong {
  color: rgba(var(--system-colour), 1);
}

.ticketPurchased .ticketBranding a:hover #pinSolid {
  fill: rgba(var(--system-colour), 1);
}

/* @Liam New styles from here onwards */

.innerWidth {
  margin: 10px 0px;
}

/* New Talent Stylings */

.eventFeaturingContainer .featuringRow {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  min-height: 50px;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 1px 0px;
}

.eventFeaturingContainer .featuringRow .featuringObject {
  height: 50px;
  width: fit-content;
}

.eventFeaturingContainer .featuringRow .featuringObject img {
  border-radius: 25px;
  width: 40px;
  height: 40px;
}

.featuringObject {
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: center;
  transition: width 0.2s linear;
  margin-right: 10px;
}

.featuringObject .info {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.drawer {
  flex-shrink: 0;
  height: 50px;
  width: 0px;
  overflow: hidden;
  transition: all 0.2s ease-in;
}

.drawer.open {
  /* @Liam I could only make this work by hardcoding a width.
        If we could do this programmatically, that would be better
        as some of the featured objects will have fewer links.
      */
  width: fit-content;
}

.drawer .name {
  color: rgba(var(--system-colour), 1) !important;
  white-space: nowrap;
  width: fit-content;
  margin: 0px;
  flex-shrink: none;
  margin-left: 20px;
  margin-bottom: 5px;
  opacity: 0;
  transition: all 0.2s linear;
}

.featuringObject .available-platform-icons-container {
  display: flex;
  min-height: 20px;
  transition: all 0.2s linear;
}

.featuringObject .available-platform-icon {
  height: 20px;
  max-width: 35px;
  margin: 0px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  opacity: 0;
  transition: all 0.2s linear;
  transition-delay: 0.2s;
}

.featuringObject .drawer.open .available-platform-icon,
.featuringObject .drawer.open .name {
  opacity: 1;
}

.featuringObject .available-platform-icon:first-child {
  margin-left: 20px;
}

.featuringObject .available-platform-icon:last {
  margin-right: 20px;
}

.featuringObject .available-platform-icon svg {
  height: 20px;
  width: auto;
}

.featuringObject .available-platform-icon svg * {
  fill: rgba(var(--secondary-colour), 1);
  transition: all 0.2s linear;
}

.featuringObject .available-platform-icon:hover svg * {
  fill: rgba(var(--system-colour), 1);
}

.featuringObject .featuringObjectImage {
  flex-shrink: 0;
  border: 2px solid rgba(var(--secondary-colour), 1);
  border-radius: 30px;
  box-sizing: border-box;
  padding: 4px;
  height: 52px;
  cursor: pointer;
  transition: all 0.2s linear;
}

.featuringObject:hover .featuringObjectImage {
  border: 2px solid rgba(var(--system-colour), 1);
}

.featuringObject .featuringObjectImage {
  border-radius: 100%;
  background: #00000020;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

/* General Form Styling */

.buttonProceed {
  margin-top: 20px;
}

form .form-field label {
  padding: 5px 10px;
  font-size: 16px;
  margin-top: 7.5px;
  transform: translateY(0%);
  background: rgba(var(--background-colour), 1);
  transition: color 0.2s linear;
  transition: all 0.1s linear;
}

form .form-field:focus-within label,
form .form-field.isNotEmpty label {
  margin-top: 0px;
  transform: translateY(-50%);
  font-size: 14px;
}

form .form-field input {
  padding-top: 5px;
  font-size: 16px;
}

form input,
form input::placeholder {
  font-size: 16px;
}

/* Check Promo Code Form Styling */

form.formPromoCodeCheck {
  padding-top: 0px;
  cursor: text;
}

form.formPromoCodeCheck input {
  cursor: text;
}

form.formPromoCodeCheck .form-field input {
  letter-spacing: 0em;
}

.formPromoCodeCheck .form-field #iconPromoCodeCheckSubmit {
  cursor: pointer;
  width: 20px;
  height: 20px;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.2s linear;
  fill: rgba(var(--secondary-colour), 1);
  opacity: 0;
  display: inline-block;
  margin-right: 0px;
}

.formPromoCodeCheck #iconPromoCodeCheckSubmit:hover {
  opacity: 1;
  fill: rgba(var(--system-colour), 1);
}

.formPromoCodeCheck .form-field:focus-within #iconPromoCodeCheckSubmit,
.formPromoCodeCheck .form-field.isNotEmpty #iconPromoCodeCheckSubmit {
  opacity: 1;
}

@media screen and (min-width: 1025px) {
  form.formPromoCodeCheck {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  .formPromoCodeCheck .buttonProceed {
    margin-left: 20px;
    max-width: 200px;
  }
}

/* Discounted Ticket Styles */

.checkoutTicketType.discounted .ticketPrice .ticketCurrency.discounted {
  margin-left: 10px;
}

.checkoutTicketType.discounted .ticketPrice span:not(.ticketFees, .discounted) {
  text-decoration: line-through;
  opacity: 0.8;
}

.ticketOrderSummaryRow.discount > * {
  color: rgba(var(--system-colour), 1);
}

/* Sold Out Tickets Styles */

.checkoutTicketType.isUnavailable {
  cursor: not-allowed;
  background-color: rgba(var(--secondary-colour), 0.05);
}

.checkoutTicketType.isUnavailable:hover {
  border: 2px transparent solid;
  background-color: rgba(var(--secondary-colour), 0.05);
}

.checkoutTicketType .isUnavailableMessage {
  display: none;
  color: rgba(var(--secondary-colour));
}

.checkoutTicketType.isUnavailable .ticketIcon {
  width: fit-content;
  height: 20px;
}

.checkoutTicketType.isUnavailable .isUnavailableMessage {
  display: block;
}

.checkoutTicketType.isUnavailable svg {
  display: none;
}

.checkoutTicketType.isUnavailable .ticketName h2 {
  color: rgba(var(--secondary-colour), 1);
}

.checkoutTicketType.isUnavailable .ticketPrice h3 {
  color: rgba(var(--secondary-colour), 1) !important;
}

.checkoutTicketType.isUnavailable .ticketNote {
  display: none;
}

/* New QR Code Styles */

.buttonAddToWallet {
  width: 100%;
}

.ticketPurchased .ticketQRCodeContainer {
  width: fit-content;
  flex-direction: column;
}

.ticketPurchased .ticketQRCodeContainer > img {
  width: 180px;
  margin-bottom: 10px;
}

.ticketPurchased .ticketQRCodeContainer .ticketQRCodeText {
  margin: 0px 20px;
  text-align: center;
}

.ticketPurchased .ticketQRCodeContainer .ticketQRCodeText > * {
  margin: 0px;
}

@media screen and (min-width: 1025px) {
  .appLandingContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: auto;
  }
  .eventTitle {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px;
    min-height: 30vh;
  }
  .eventArtwork {
    position: fixed;
    left: 0;
    top: 0;
    width: 50%;
    height: calc(100%);
  }
  .eventOverview h3 {
    font-size: 1em;
    font-weight: 500;
  }
  .eventOverview h1 {
    font-size: 3em;
    font-weight: 500;
  }
  .artworkBackground .artworkBackgroundGradient {
    z-index: 1;
    background-image: linear-gradient(
      to right,
      rgba(var(--background-colour), 0.25) 0%,
      rgba(var(--background-colour), 1)
    );
  }
  .eventArtwork .artworkHeroContainer {
    padding: 40px;
  }
  .eventArtwork .artworkHero {
    border-radius: 20px;
  }
  .appCheckout {
    width: 50%;
    backdrop-filter: none;
  }
  .appCheckoutContainer {
    backdrop-filter: none;
    justify-content: flex-start;
    padding: 20px;
    margin: auto;
  }
  .appCheckoutContainer .cardNavigationControls {
    margin-bottom: initial;
  }
  .appCheckoutEventOverview {
    padding: 20px;
  }
  .ticketsPurchasedSlider {
    padding-top: 0px;
  }
  .ticketPurchasedWrapper {
    align-items: flex-start;
  }
}
