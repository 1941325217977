.progress-blips {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.step-blip {
  height: 4px;
  width: 4px;
  border-radius: 2px;
  background-color: rgb(var(--primary-colour));
  margin-right: 10px;
  transition: width 1s ease;
}

.step-blip.active {
  width: 20px;
  border-radius: 2px;
  background-color: rgb(var(--system-colour));
}

.complete {
  background-color: rgba(var(--system-colour), 1);
  transition: 1s;
}